import { ConfigKey } from "@backend";
import { ReactNode, createContext, useContext, useState } from "react";
import { trpc } from "../api/api";

const ConfigContext = createContext<Map<ConfigKey, string> | undefined>(
  undefined,
);

export const PublicConfigProvider = ({ children }: { children: ReactNode }) => {
  const [configData] = trpc.config.public.useSuspenseQuery();
  const [config] = useState<Map<ConfigKey, string>>(() => {
    const configParsed = new Map();
    (
      configData as {
        value: string;
        key: string;
      }[]
    ).forEach((c) => configParsed.set(c.key, c.value));
    return configParsed;
  });

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

const usePublicConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error(
      "usePublicConfig must be used within a PublicConfigProvider",
    );
  }
  return context!;
};

export default usePublicConfig;
