import {
  createTRPCProxyClient,
  createTRPCReact,
  httpBatchLink,
} from "@trpc/react-query";
import { AppRouter } from "../../../server/src/server";

export const trpc = createTRPCReact<AppRouter>();

export const trpcHttpBatchLink = [
  httpBatchLink({
    url: import.meta.env.VITE_BACKEND_URL + "/trpc",
    fetch(url, options) {
      return fetch(url, {
        ...options,
        credentials: "include",
      });
    },
    async headers() {
      return {
        // authorization: getAuthCookie(),
      };
    },
  }),
];

export const trcpProxyClient = createTRPCProxyClient<AppRouter>({
  links: trpcHttpBatchLink,
});
