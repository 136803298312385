export default function ProgressBar(props: {
  currentStep: number;
  steps: number;
}) {
  if (props.currentStep <= 0 || props.steps === 0) return null;
  return (
    <div
      className="progressbar"
      style={{
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        float: "none",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <div id="progress">
        <div id="progress-bar"></div>
        <ul id="progress-num">
          {Array.from({ length: props.steps }, (_, i) => (
            <li
              key={i}
              className={i <= props.currentStep - 1 ? "step active" : "step"}
            >
              {i + 1}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
