import ErrorIcon from "@mui/icons-material/Error";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { atom, useAtom } from "jotai";
import React, { Suspense, useEffect } from "react";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { ErrorBoundary } from "react-error-boundary";
import { initReactI18next, useTranslation } from "react-i18next";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import Meta from "./Meta";
import { trcpProxyClient, trpc } from "./api/api";
import CenteredSpinner from "./components/CenteredSpinner";
import ProgressNavbar from "./components/Steps/ProgressNavbar";
import { PublicConfigProvider } from "./hooks/usePublicConfig";

const ReservePage = React.lazy(() => import("./pages/ReservePage"));
const ReservePageEvent = React.lazy(() => import("./pages/ReservePageEvent"));
const CancelReservationPage = React.lazy(
  () => import("./pages/CancelReservationPage"),
);
const AdminPage = React.lazy(() => import("./pages/AdminPage"));
const ViewReservationPage = React.lazy(
  () => import("./pages/ViewReservationPage"),
);
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const TestPage = React.lazy(() => import("./pages/TestPage"));

export const languageAtom = atom("en");

function ErrorHandler({ error }: any) {
  console.log(error);
  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="tooltip" placement="bottom">
          {error.message}
        </Tooltip>
      }
    >
      <ErrorIcon
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
        }}
      />
    </OverlayTrigger>
  );
}

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options: any, url: any, payload: any, callback: any) => {
    const [lng] = url.split("|");
    trcpProxyClient.translations.load
      .query({ lang: lng })
      .then((response) => {
        callback(null, {
          data: response,
          status: 200,
        });
      })
      .catch(() => {
        toast.error("Failed to load translations for " + lng);
        import("src/lang/en.json").then((def) => {
          callback(null, {
            data: def,
            status: 200, //200 to allow using the website anyway
          });
        });
      });
  },
};

const languageDetector = new LanguageDetector(null, {
  lookupFromPathIndex: 0,
  convertDetectedLanguage(lng) {
    if (supportedLanguages.includes(lng)) return lng;
    return "en";
  },
});
const supportedLanguages = ["en", "nl"];

i18n
  .use(initReactI18next)
  .use(backend)
  .use(languageDetector)
  .init({
    backend: backendOptions,
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: supportedLanguages,
    defaultNS: "ns1",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: ["path", "navigator"],
      lookupFromPathIndex: 0,
    },
  });

const LangRoute = () => {
  const { lang } = useParams<"lang">();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  React.useEffect(() => {
    // Update i18n language if URL lang changes
    if (lang && lang !== i18n.language && supportedLanguages.includes(lang)) {
      i18n
        .changeLanguage(lang)
        .catch((error) => console.error("Failed to change language:", error));
    }
  }, [lang, i18n]);

  React.useEffect(() => {
    // Update URL if i18n language changes and it's not from URL change
    const handleLanguageChange = () => {
      if (i18n.language !== lang) {
        const searchParams = new URLSearchParams(location.search);
        navigate(
          `/${i18n.language}/${location.pathname.substring(4)}?${searchParams.toString()}`,
          {
            replace: true,
          },
        );
      }
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n, lang, navigate]);

  if (!lang || !supportedLanguages.includes(lang)) {
    return <DynamicLanguageRedirect />;
  }

  return <Outlet />;
};

const DynamicLanguageRedirect = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const pathLang = location.pathname.split("/")[1];
  if (supportedLanguages.includes(pathLang)) {
    // If the current language is supported, no redirect needed, should be handled by LangRoute, however
    return null;
  }

  const detectedLang = i18n.language.split("-")[0]; // e.g., 'en-US' -> 'en'
  const defaultLang = supportedLanguages.includes(detectedLang)
    ? detectedLang
    : "en";
  const newPath = `/${defaultLang}${location.pathname}${location.search}`;

  return <Navigate to={newPath} replace />;
};

export const stepAtom = atom(0);
export const totalStepsAtom = atom(0);

export default function App() {
  const [lang, setLang] = useAtom(languageAtom);
  const { i18n } = useTranslation();

  useEffect(() => {
    setLang(i18n.language.substring(0, 2));
  }, [i18n.language]);

  const [cookie, setCookie] = useCookies(["session", "statistics", "v"]);

  const uploadError = trpc.statistics.exception.useMutation();
  const uploadVisit = trpc.statistics.visit.useMutation();

  useEffect(() => {
    if (cookie.v) return;
    uploadVisit
      .mutateAsync()
      .then((data: { userID: string; visitID: string }) => {
        //sesh cookie keeps track whether this visit has already been tracked. Expires on end of browser session
        setCookie("v", data.visitID, {
          sameSite: true,
          domain: import.meta.env.VITE_DOMAIN,
          secure: true,
          path: "/",
        });
        setCookie("statistics", data.userID, {
          maxAge: 34560000,
          sameSite: true,
          domain: import.meta.env.VITE_DOMAIN,
          secure: true,
          path: "/",
        });
      });
  }, []);

  const setError = (msg: string) => {
    if (msg && msg.length > 0) {
      uploadError.mutate({ error: msg });
      toast.error(
        msg + " If this error persists, please get in touch with us.",
      );
    }
  };

  return (
    <div className="App">
      <Meta lang={lang} />
      <Container>
        <ProgressNavbar />
        <ErrorBoundary
          FallbackComponent={ErrorHandler}
          onError={(e) => {
            if (e.message.startsWith("NetworkError")) {
              setError("The server is offline!");
            } else {
              setError(e.message);
            }
          }}
        >
          <Routes>
            <Route path="/" element={<Navigate to={`${lang}/`} replace />} />
            <Route path=":lang" element={<LangRoute />}>
              <Route
                index
                element={
                  <Suspense
                    fallback={
                      <>
                        <CenteredSpinner />
                      </>
                    }
                  >
                    <PublicConfigProvider>
                      <ReservePage setError={setError} />
                    </PublicConfigProvider>
                  </Suspense>
                }
              />
              {/* <Route
              path="/"
              element={
                <Suspense fallback={<CenteredSpinner />}>
                  <LandingPage
                    title={i18n.t("groups.landing_page_card.title")}
                    content={i18n.t("groups.landing_page_card.content")}
                    buttonText={i18n.t("groups.landing_page_card.button")}
                    onButtonClick={() => navigate("/groups")}
                    background={<BackGround />}
                  />
                </Suspense>
              }
            /> */}
              <Route
                path="events"
                element={
                  <Suspense
                    fallback={
                      <>
                        <CenteredSpinner />
                      </>
                    }
                  >
                    <PublicConfigProvider>
                      <ReservePageEvent setError={setError} />
                    </PublicConfigProvider>
                  </Suspense>
                }
              />
              <Route
                path="cancel"
                element={
                  <Suspense fallback={<CenteredSpinner />}>
                    <CancelReservationPage />
                  </Suspense>
                }
              />
              <Route
                path="view"
                element={
                  <Suspense fallback={<CenteredSpinner />}>
                    <ViewReservationPage />
                  </Suspense>
                }
              />
              <Route
                path="admin"
                element={
                  <Suspense fallback={<CenteredSpinner />}>
                    {!cookie.session ? (
                      <LoginPage
                        cookie={cookie}
                        setCookie={setCookie}
                        setError={setError}
                      />
                    ) : (
                      <AdminPage />
                    )}
                  </Suspense>
                }
              />
              <Route
                path="test"
                element={
                  <Suspense fallback={<CenteredSpinner />}>
                    <TestPage />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path="*"
              element={
                <p style={{ textAlign: "center" }}>Page not found found</p>
              }
            />
          </Routes>
        </ErrorBoundary>
      </Container>
    </div>
  );
}
