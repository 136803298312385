import { useEffect, useState } from "react";
import { Col, Row, Spinner, SpinnerProps } from "react-bootstrap";

export default function CenteredSpinner(props: {
  animation?: SpinnerProps["animation"];
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <Row className="mt-5">
      <Col />
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner
          hidden={!show}
          animation={props.animation}
          style={{
            position: "absolute",
            display: "flex",
            textAlign: "center",
          }}
        />
      </Col>
      <Col />
    </Row>
  );
}
