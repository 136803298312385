import Language from "@mui/icons-material/Language";
import { CSSProperties } from "react";
import { Form, InputGroup } from "react-bootstrap";

export default function LanguageSelector(props: {
  language: string;
  setLanguage: (lang: string) => void;
  disabled?: boolean;
  style?: CSSProperties;
}) {
  return (
    <InputGroup
      style={{
        width: "120px",
        marginLeft: "0px",
        ...props.style,
      }}
    >
      <InputGroup.Text>
        <Language />
      </InputGroup.Text>
      <Form.Select
        disabled={props.disabled}
        onChange={(e) => {
          props.setLanguage(e.target.value);
        }}
        value={props.language}
        style={{
          fontFamily: "AveriaSans, sans-serif",
        }}
      >
        <option value="nl">NL</option>
        <option value="en">EN</option>
      </Form.Select>
    </InputGroup>
  );
}
