import { Helmet } from "react-helmet";

export default function Meta({ lang }: { lang: string }) {
  const isDutch = lang === "nl";

  const metaInfo = {
    title: isDutch
      ? "Meeting aan Zee - Groepsboekingen & Events"
      : "Meeting aan Zee - Group Booking & Events",
    description: isDutch
      ? "Plan uw groepsbijeenkomsten of events bij de beste kustrestaurants in Nederland met Meeting aan Zee, voor groepen van 10 personen of meer."
      : "Plan your group gatherings or events at the best coastal restaurants in the Netherlands with Meeting aan Zee, for groups of 10 or more.",
  };

  if (import.meta.env.VITE_TEST_WEBSITE_DESCRIPTION) {
    metaInfo.title = import.meta.env.VITE_TEST_WEBSITE_DESCRIPTION;
  }

  return (
    <Helmet htmlAttributes={{ lang }}>
      <link
        rel="alternate"
        hrefLang="en"
        href="https://www.meetingaanzee.nl/en/"
      />
      <link
        rel="alternate"
        hrefLang="nl"
        href="https://www.meetingaanzee.nl/nl/"
      />
      <link
        rel="alternate"
        hrefLang="x-default"
        href="https://www.meetingaanzee.nl/en/"
      />

      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{metaInfo.title}</title>
      <meta name="description" content={metaInfo.description} />
      <meta name="author" content="Meeting aan Zee" />
      <link
        rel="canonical"
        href={`https://www.meetingaanzee.nl/${isDutch ? "nl" : "en"}/`}
      />
      {/* 
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`https://www.meetingaanzee.nl/${isDutch ? "nl" : "en"}/`}
      />
      <meta property="og:title" content={metaInfo.title} />
      <meta property="og:description" content={metaInfo.description} />
      <meta
        property="og:image"
        content="https://www.meetingaanzee.nl/path/to/image.jpg"
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:url"
        content={`https://www.meetingaanzee.nl/${isDutch ? "nl" : "en"}/`}
      />
      <meta name="twitter:title" content={metaInfo.title} />
      <meta name="twitter:description" content={metaInfo.description} />
      <meta
        name="twitter:image"
        content="https://www.meetingaanzee.nl/path/to/image.jpg"
      /> */}
    </Helmet>
  );
}
