import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons/faRightFromBracket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { InputGroup, Modal, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { languageAtom, stepAtom, totalStepsAtom } from "src/App";
import { trpc } from "src/api/api";
import { useWindowSize } from "src/hooks/useWindowSize";
import LanguageSelector from "../LanguageSelector";
import ProgressBar from "./ProgressBar";

export default function ProgressNavbar() {
  const { i18n, t } = useTranslation();
  const [lang, setLang] = useAtom(languageAtom);

  const [currentStep] = useAtom(stepAtom);
  const [totalSteps] = useAtom(totalStepsAtom);

  const [cookie, , removeCookie] = useCookies(["reservations", "session"]);
  const location = useLocation();
  const navigate = useNavigate();

  const windowSize = useWindowSize();
  const isMobile = windowSize < 990;

  const logout = trpc.login.logout.useMutation();

  const isActive = (path: string) => {
    const sanitized = location.pathname.replace(/[#?].*$/, "");
    return sanitized === `/${lang}${path}` || sanitized === `/${lang}${path}/`;
  };

  const [showContact, setShowContact] = useState(false);
  const [contactContent, setContactContent] = useState("Loading...");
  useEffect(() => {
    if (showContact) {
      setContactContent(
        t(
          isActive("/events")
            ? "contact.events.content"
            : "contact.booking.content",
        ).replaceAll("\n", "<br/>"),
      );
    }
  }, [showContact]);

  return (
    <>
      <div style={{ marginBottom: "80px" }} />
      <Modal size="lg" show={showContact} onHide={() => setShowContact(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body
          dangerouslySetInnerHTML={{
            __html: contactContent,
          }}
        />
      </Modal>
      <Navbar
        fixed="top"
        expand="lg"
        variant="dark"
        style={{
          textAlign: "center",
          zIndex: 3,
          minHeight: "58px",
        }}
      >
        <Container className="">
          <ProgressBar currentStep={currentStep} steps={totalSteps} />

          <Navbar.Brand className="pl-2" hidden={isMobile && currentStep > 0}>
            {isActive("/events") ? "Events" : isActive("/") ? "Groups" : ""}
          </Navbar.Brand>

          {!isActive("/admin") && (
            <InputGroup
              className="lang-selector "
              style={{
                width: "120px",
                marginRight: "auto",
              }}
              hidden={isMobile && currentStep > 0}
            >
              <LanguageSelector
                language={lang}
                setLanguage={(lang) => {
                  i18n.changeLanguage(lang);
                  setLang(lang);
                }}
              />
            </InputGroup>
          )}

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="justify-end"
            style={{ marginRight: "10px" }}
            hidden={isMobile && currentStep > 0}
          />

          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav navbarScroll>
              {(currentStep <= 0 || totalSteps === 0) && (
                <>
                  <Nav.Link as={Link} to={`/${lang}/`} active={isActive("/")}>
                    Groups
                  </Nav.Link>
                  {import.meta.env.VITE_DISABLE_GROUP_3 !== "true" && (
                    <Nav.Link
                      as={Link}
                      to={`/${lang}/events`}
                      active={isActive("/events")}
                    >
                      Events
                    </Nav.Link>
                  )}
                  {!isActive("/admin") && (
                    <Nav.Link onClick={() => setShowContact(true)}>
                      Info
                    </Nav.Link>
                  )}

                  {cookie.session && (
                    <>
                      <Nav.Link
                        as={Link}
                        to={`/${lang}/admin`}
                        active={isActive("/admin")}
                      >
                        Admin
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to={cookie.session ? "/" : `/${lang}/admin`}
                        onClick={async () => {
                          if (cookie.session) {
                            logout.mutate();
                            removeCookie("session", {
                              sameSite: true,
                              domain: import.meta.env.VITE_DOMAIN,
                              secure: true,
                            });
                          }
                          navigate("/");
                        }}
                      >
                        <FontAwesomeIcon
                          className="right"
                          // icon={cookie.session ? faRightFromBracket : faGear}
                          icon={faRightFromBracket}
                        />
                      </Nav.Link>
                    </>
                  )}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
