import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TRPCClientError } from "@trpc/client";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppRouter } from "../../server/src/server";
import App from "./App";
import "./App.css";
import "./Calendar.css";
import { trpc, trpcHttpBatchLink } from "./api/api";

import { init } from "@sentry/react";
if (import.meta.env.VITE_TEST !== "true") {
  init({
    dsn: "https://584ef4df9179d6209949fdbb8b49b688@o4507543529127936.ingest.de.sentry.io/4507543530897488",
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/meetingaanzee\.nl\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  import("./SentryIntegrations").then((t) => t.initSentryIntegrations());
}

declare global {
  interface String {
    format(...args: any[]): string;
  }
}

String.prototype.format = function () {
  var args = arguments;
  return this.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const showSuccessToast = (msg: string) => {
  toast.success(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};

function Root() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            onError: (error) => {
              const e = error as TRPCClientError<AppRouter>;
              const { message } = e as TRPCClientError<AppRouter>;
              toast.error(message);
              if (message.startsWith("Invalid or expired")) {
                document.cookie =
                  "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              }
            },
          },
          mutations: {
            onError: (error) => {
              const { message } = error as TRPCClientError<AppRouter>;
              toast.error(message);
            },
          },
        },
      }),
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: trpcHttpBatchLink,
    }),
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="colored"
          transition={Bounce}
        />
        <App />
      </QueryClientProvider>
    </trpc.Provider>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  // </React.StrictMode>
);
